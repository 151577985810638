.introduction {
    text-align: center;
    position: relative; 
}



.introduction  h1 {
    background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
	font-family: 'Playfair Display', serif;
    position: relative;
	text-transform: uppercase;	
	font-size: 500%;
	margin: 0;
	font-weight: 100;
}

h1::after {
    background: none;
    content: attr(data-heading) / "";
    left: 0;
	top: 0;
    z-index: -1;
    position: absolute;
    text-shadow: 
		-1px 0 1px #c6bb9f, 
		0 1px 1px #c6bb9f, 
		5px 5px 10px rgba(0, 0, 0, 0.4),
		-5px -5px 10px rgba(0, 0, 0, 0.4);
}

.introduction h2{
    font-size: 30px;
    color: #decc98;
    font-family: 'Playfair Display', serif;
}


.introduction p {
    font-size: 22px;
    line-height: 1.6;
    color: #decc98
}
.welcome {
    padding-bottom: 3%;
}

.about-us {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8%;
}

.about-us .content {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.about-us .text {
    flex: 1;
    margin-right: 20px;
}

.about-us .text p {
    font-size: 18px;
    line-height: 1.6;
    color: #decc98;
}

.about-us h2 {
    background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
    font-size: 250%;
    margin-bottom: 1.5%;
    font-weight: 100;
    position: relative;
}

.about-us h2::after {
    background: none;
    content: attr(data-heading) / "";
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    text-shadow:
        -1px 0 1px #c6bb9f,
        0 1px 1px #c6bb9f,
        5px 5px 10px rgba(0, 0, 0, 0.4),
        -5px -5px 10px rgba(0, 0, 0, 0.4);
}

.about-us .practice-areas {
    list-style: none;
    padding-right: 10%;
    margin: 20px 0;
    margin-top: 5%;
    
}

.about-us .practice-areas li {
    font-size: 18px;
    line-height: 1.6;
    color: #fff3e0;
    margin: 10px 0;
    position: relative;
    padding-left: 20px;
}

.about-us .practice-areas li::before {
    content: "✔";
    position: absolute;
    left: 0;
    color: #decc98;
    font-size: 18px;
    line-height: 1.6;
}
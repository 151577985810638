.why-choose-us {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8%;
  }
  
  .why-choose-us .content {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  
  .why-choose-us .text {
    flex: 1;
    margin-right: 20px;
  }
  .why-choose-us  .text p {
    font-size: 18px;
    line-height: 1.6;
    color: #decc98;
}
  
  .why-choose-us .text h2 {
    background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
    font-size: 250%;
    margin-bottom: 1.5%;
    font-weight: 100;
    position: relative;
  }
    
  .why-choose-us .image-container {
    flex: 1;
    text-align: right;
    margin-top: 6.5%;
    margin-right: 7%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  
  
  .why-choose-us .image-container:hover::before {
    transform: scaleX(1); 
  }
  
  .why-choose-us .pic {
    width: 85%;
    border-radius: 5%;
    height: auto;
    transition: transform 0.5s ease; 
  }
  
  .why-choose-us .pic:hover {
    transform: scale(1.1); 
  }
  
  
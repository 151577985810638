.contact-us {
    text-align: center;
    margin-top: 8%;
}

.contact-us h2 {
    background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    position: relative;
    text-transform: uppercase;
    font-size: 2.5rem; 
    margin-bottom: 1.5%;
    font-weight: 100;
}

.contact-us p {
    font-size: 16px;
    line-height: 1.5;
    color: #decc98;
}

.contact-us div {
    font-size: 16px;
    line-height: 1.5;
    color: #887850;
    margin-top: 1%;
}
.our-services {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8%;
}

.our-services .content {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.our-services .text {
    flex: 1;
    margin-right: 20px;
}

.our-services .text p {
    font-size: 20px;
    line-height: 1.5;
    color: #decc98;
}

.our-services h2 {
    background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
    font-size: 250%;
    margin-bottom: 5%;
    margin-top: 1.5%;
    font-weight: 100;
}

.our-services h2::after {
    background: none;
    content: attr(data-heading) / "";
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    text-shadow:
        -1px 0 1px #c6bb9f,
        0 1px 1px #c6bb9f,
        5px 5px 10px rgba(0, 0, 0, 0.4),
        -5px -5px 10px rgba(0, 0, 0, 0.4);
}

.our-services .services-list {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.our-services .services-list li {
    position: relative;
    background-color: #fff3e0;
    border: 2px solid #887850;
    border-radius: 20px 50px;
    padding: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #887850;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    transform: skewY(-2deg);
}

.our-services .services-list li:hover {
    transform: translateY(-10px) skewY(-2deg);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    color: #3a2c0f;
}

.our-services .services-list li::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border: 2px dashed #887850;
    border-radius: 20px 50px;
    transform: rotate(-2deg);
    z-index: -1;
}
